// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-board-js": () => import("./../src/templates/board.js" /* webpackChunkName: "component---src-templates-board-js" */),
  "component---src-templates-credits-js": () => import("./../src/templates/credits.js" /* webpackChunkName: "component---src-templates-credits-js" */),
  "component---src-templates-index-js": () => import("./../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-instrument-js": () => import("./../src/templates/instrument.js" /* webpackChunkName: "component---src-templates-instrument-js" */)
}

